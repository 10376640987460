import { Button } from '../../components/shared/Button/Button';
import PlusIcon from '../../assets/plus-icon.svg?react';
import MinusIcon from '../../assets/minus-icon.svg?react';
import VipImage from '../../assets/vip-service.jpeg';
import { Heading2, HeadingUI3 } from '../../components/shared/Heading';
import HTMLParser from '../../components/HTMLParser/HTMLParser';
import { formatMoney } from '../../utils/formatMoney';
import { useRootStore } from '../../stores/useRootStore';
import { Dialog } from '../../components/shared/Dialog/Dialog';
import { CloseModalButton } from '../../components/shared/CloseModalButton';
import { ReactNode, useState } from 'react';
import { SERVICE_PRICE_TYPE } from '../../apiMethods/getServices';
import { observer } from 'mobx-react-lite';
import { useNightsCounter } from '../../utils/useNightsCounter';
import { Clamped } from '../../components/shared/Clamped';

const Chip = ({ children }: { children: string }) => (
  <span className="whitespace-nowrap text-sm font-light text-neutral-70">{children}</span>
);

type ServiceProps = {
  id: number;
  name: string;
  long_description: string;
  price: number;
  price_type: SERVICE_PRICE_TYPE;
  short_description: string;
  sub_services: { id: number; name: string; price: number }[];
  sub_title?: null | string;
};

export const Service = observer(
  ({
    service,
    addService,
    addSubService,
    addQuantityService,
  }: {
    service: ServiceProps;
    addService: (params: { serviceId: number; subServiceId?: number; quantity?: number }) => void;
    addSubService: (params: {
      serviceId: number;
      subServiceId: number;
      name: string;
      price: number;
    }) => void;
    addQuantityService: (params: {
      serviceId: number;
      quantity: number;
      priceAnnotation: string;
    }) => void;
  }) => {
    const { name, long_description, price, short_description, sub_services, sub_title } = service;
    const { paramsStore } = useRootStore();

    const { from, to } = paramsStore.appliedParams?.range || {};
    const nightsStat = useNightsCounter({ from, to });

    const onClose = () => {
      setOpenedService(null);
    };

    const [openedService, setOpenedService] = useState<ServiceProps | null>(null);

    const onSubServiceSelect = (params: { id: number; name: string; price: number }) => {
      addSubService({
        serviceId: service.id,
        subServiceId: params.id,
        price: params.price,
        name: params.name,
      });
    };

    const onServiceClick = (serviceId: number, quantity?: number, annotation?: string) => {
      const service = paramsStore.getService(serviceId);

      if (service?.price_type === SERVICE_PRICE_TYPE.FIX_PRICE) {
        addService({ serviceId });
      }

      if (service?.price_type === SERVICE_PRICE_TYPE.SUB_SERVICE_PRICE) {
        setOpenedService(service);
      }

      if (
        service?.price_type === SERVICE_PRICE_TYPE.PER_NIGTH &&
        quantity != undefined &&
        annotation
      ) {
        addQuantityService({ serviceId, quantity, priceAnnotation: annotation });
      }
    };

    const priceAnnotation =
      SERVICE_PRICE_TYPE.PER_NIGTH === service.price_type ? `За ${nightsStat}` : '';

    return (
      <>
        <div className="my-6 w-full xl:flex">
          <img
            src={VipImage}
            alt="vip service"
            className="mb-2 h-[160px] w-full object-cover xl:h-[200px] xl:w-[200px]"
          />

          <div className="flex-1 xl:pl-5">
            <HeadingUI3 className="xl:mb-0">{name}</HeadingUI3>
            <div className="-mr-5 flex gap-3 overflow-x-auto pr-5">
              {sub_title && <Chip>{sub_title}</Chip>}
            </div>

            {long_description && (
              <div className="my-3">
                <Clamped label="Подробнее об услуге" height={24 * 3}>
                  <HTMLParser html={long_description} />
                </Clamped>
              </div>
            )}

            <div className="w-full justify-end xl:flex xl:items-center">
              {service.price_type !== SERVICE_PRICE_TYPE.SUB_SERVICE_PRICE && (
                <div className="my-2 xl:flex-1">
                  <span className="text-2xl">{formatMoney(price)} &#8381;</span>
                  {priceAnnotation && (
                    <span className="text-base font-light leading-5 text-neutral-70">
                      {' '}
                      / {priceAnnotation}
                    </span>
                  )}
                </div>
              )}
              <PriceTypeButton
                count={paramsStore.getSelectedService(service.id)?.quantity ?? 0}
                type={service.price_type}
                onClick={(quantity?: number) => {
                  if (quantity && quantity > 99) {
                    return;
                  }
                  onServiceClick(service.id, quantity, priceAnnotation.toLowerCase());
                }}
                selected={paramsStore.isServiceSelected(service.id)}
              />
            </div>
          </div>
        </div>

        <Dialog isOpen={Boolean(openedService)} onClose={onClose}>
          <div className="xl:w-[640px]">
            <CloseModalButton onClose={onClose} />
            <img
              src={VipImage}
              alt="vip service"
              className="mb-2 h-[240px] w-full object-cover xl:h-[370px]"
            />
            <div className="p-5 xl:p-8">
              <Heading2>{name}</Heading2>

              <p className="mb-3 text-base font-light leading-7">
                <div className="pb-3">
                  <HTMLParser html={short_description} />
                </div>
                <HTMLParser html={long_description} />
              </p>
              {sub_services.map(subService => (
                <Item
                  key={subService.id}
                  data={subService}
                  onClick={() => onSubServiceSelect(subService)}
                  selected={paramsStore.isServiceSelected(service.id, subService.id)}
                />
              ))}
            </div>
          </div>
        </Dialog>
      </>
    );
  },
);

const buttonMap: {
  [P in SERVICE_PRICE_TYPE]: (params: {
    onClick: (quantity?: number) => void;
    selected: boolean;
    count: number;
  }) => ReactNode;
} = {
  FIX_PRICE: ({ onClick, selected }) => (
    <Button
      style={selected ? 'secondary' : 'accent'}
      className="w-full xl:w-auto "
      onClick={onClick}
    >
      {selected ? 'Удалить' : 'Добавить'}
    </Button>
  ),

  SUB_SERVICE_PRICE: ({ onClick, selected }) => (
    <Button
      style={selected ? 'secondary' : 'accent'}
      className="w-full xl:w-auto "
      onClick={onClick}
    >
      Подробнее
    </Button>
  ),
  PER_NIGTH: ({ onClick, count }) => {
    const increment = () => {
      const newCount = count + 1;
      onClick(newCount);
    };

    const decrement = () => {
      const newCount = count - 1;
      if (newCount < 0) {
        return;
      }
      onClick(newCount);
    };

    return (
      <div className="flex items-center">
        <Button onClick={decrement}>
          <MinusIcon />
        </Button>
        <div className="flex w-[50px] justify-center px-5">{count}</div>
        <Button onClick={increment}>
          <PlusIcon />
        </Button>
      </div>
    );
  },
};

const PriceTypeButton = ({
  type,
  onClick,
  selected,
  count,
}: {
  type: SERVICE_PRICE_TYPE;
  onClick: () => void;
  selected: boolean;
  count: number;
}) => {
  return buttonMap[type]({ onClick, count, selected });
};

const Item = ({
  data,
  onClick,
  selected,
}: {
  data: { id: number; name: string; price: number };
  onClick: (id: number) => void;
  selected: boolean;
}) => {
  return (
    <article className="mb-4 mt-3 flex gap-5">
      <div className="flex-1">
        <p className="text-lg font-medium">{data.name}</p>
        <span className="text-lg font-light">{formatMoney(data.price)} &#8381;</span>
      </div>
      {selected ? (
        <Button onClick={() => onClick(data.id)} style="secondary">
          Удалить
        </Button>
      ) : (
        <Button onClick={() => onClick(data.id)}>Добавить</Button>
      )}
    </article>
  );
};
