import { observer } from 'mobx-react-lite';
import { useRootStore } from '../stores/useRootStore';
import { format } from 'date-fns';
import noun from 'plural-ru';
import CloseIcon from '../assets/close-icon.svg?react';
import './custom-slider.css';
import { ru } from 'date-fns/locale';
import { useNightsCounter } from '../utils/useNightsCounter';
import { useShowServices } from '../utils/useShowServices';

const useGuestsCounter = (adults: number, childs: number) => {
  const adultsNoun = noun(adults, 'взрослый', 'взрослых', 'взрослых');
  const childsNoun = noun(childs, 'ребёнок', 'ребёнка', 'детей');

  return `${adults} ${adultsNoun}` + (childs ? `, ${childs} ${childsNoun}` : '');
};

const formatDate = (date?: Date) => (date ? format(date, 'd MMMM yyyy', { locale: ru }) : '-');

const InfoRow = ({
  label,
  value,
  isTotal,
}: {
  label: string;
  value: string;
  isTotal?: boolean;
}) => (
  <div className={`my-4 grid grid-cols-2 items-start font-light `}>
    <span className={isTotal ? 'font-medium' : ''}>{label}</span>
    <span className={isTotal ? 'font-normal' : ''}>{value}</span>
  </div>
);

export const OrderSummary = observer(({ onClose }: { onClose?: () => void }) => {
  const { paramsStore } = useRootStore();

  const showServices = useShowServices();

  const { from, to } = paramsStore.appliedParams?.range || {};
  const [adults, childs] = paramsStore.countGuests(paramsStore.guestRooms);

  const nightsStat = useNightsCounter({ from, to });
  const guestsStat = useGuestsCounter(adults, childs);

  return (
    <>
      <div className="flex items-center justify-between">
        <h4 className="mt-6 text-2xl leading-[32px]">Информация о заказе</h4>
        <CloseIcon className="xl:hidden" onClick={onClose} />
      </div>
      <section>
        <h6 className="mt-11 text-[18px] leading-[28px]">Параметры</h6>
        <InfoRow label="Прибытие" value={formatDate(paramsStore.from)} />
        <InfoRow label="Выезд" value={formatDate(paramsStore.to)} />
        <InfoRow label="Проживание" value={nightsStat} />
        <InfoRow label="Гости" value={guestsStat} />

        <h6 className="mt-11 text-[18px] leading-[28px]">Номер</h6>
        <InfoRow label="Категория" value={paramsStore.room?.name || '-'} />
        <InfoRow label="Количество" value={String(paramsStore.roomsCount || '-')} />
        {paramsStore.rate && <InfoRow label="Тариф" value={paramsStore.rate.name} />}

        {showServices && paramsStore.selectedServices.length > 0 && (
          <>
            <h5 className="mt-11 text-xl leading-[30px]">Дополнительные услуги</h5>
            {paramsStore.selectedServices.map(service => (
              <InfoRow
                label={service.name}
                value={`${service.price * service.quantity}р ${service.priceAnnotation ? '/ ' + service.priceAnnotation : ''}`}
              />
            ))}
          </>
        )}

        {paramsStore.totalPrice && (
          <>
            <div className="-mx-8 my-10 border-b border-neutral-40"></div>
            <InfoRow
              label="Всего"
              value={`${paramsStore.totalPrice} ${String.fromCharCode(8381)} / ${nightsStat}`}
              isTotal
            />
          </>
        )}
      </section>
    </>
  );
});
