import { RateMode } from '../stores/paramsStore';
import { api } from '../utils/api';

// Типы цен для доп услуг
export enum SERVICE_PRICE_TYPE {
  'FIX_PRICE' = 'FIX_PRICE',
  'PER_NIGTH' = 'PER_NIGTH',
  'SUB_SERVICE_PRICE' = 'SUB_SERVICE_PRICE',
}

export enum SERVICE_PRICE_TYPE_LABEL {
  'FIX_PRICE' = 'Фиксированная цена',
  'PER_NIGTH' = 'Цена от количества ночей',
  'SUB_SERVICE_PRICE' = 'Цена на подуслугу',
}

export type Service = {
  id: number;
  name: string;
  short_description: string;
  long_description: string;
  img: string;
  price_type: SERVICE_PRICE_TYPE;
  fix_price: number;
  per_night_price: number;
  max_guests: number;
  sub_service_isset: boolean;
  full_boarding: boolean;
  card_payment: boolean;
  sub_services: { id: number; name: string; price: number }[];
  price: number;
  price_valid: boolean;
  order_number: number;
};

type ServicesParams = {
  arrival: string;
  departure: string;
  promo_code: string;
  rooms: { adults: number; childs: { id: number; code: string; count: number }[] }[];
  rate_flow: RateMode;
};

export const getServices = async (hotelId: number, params: ServicesParams) => {
  try {
    const url = `/api/v1/availability/services/${hotelId}/`;

    const bodyRequest = {
      ...params,
    };
    const response = await api.post<Service[]>(url, bodyRequest);
    return response;
  } catch (error) {
    console.log(error);
  }
};
