import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import ChevronUpIcon from '../../assets/chevron-up-icon.svg?react';
import shave from 'shave';

export const Clamped = ({
  label,
  children,
  height,
  className,
}: {
  label: string;
  children: ReactNode;
  height: number;
  className?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef<HTMLParagraphElement>(null);
  const [needToShave, setNeed] = useState(false);

  const update = useCallback(() => {
    if (!ref.current) {
      return;
    }
    shave(ref.current, isOpen ? Infinity : height);

    const visibleText = ref.current?.innerText.length ?? 0;
    const sourceText = ref.current?.textContent?.length ?? 0;

    const need = visibleText < sourceText;

    !isOpen && setNeed(need);
  }, [height, isOpen]);

  useEffect(() => {
    update();

    window.addEventListener('resize', update);
    return () => window.removeEventListener('resize', update);
  }, [update]);

  return (
    <>
      <p ref={ref} className={className}>
        {children}
      </p>
      {needToShave && (
        <span
          className="mb-1 flex cursor-pointer font-light text-primary-300"
          onClick={() => setIsOpen(s => !s)}
        >
          {label}

          <ChevronUpIcon className={`custom-icon ml-2 ${isOpen ? '' : 'rotate-180'}`} />
        </span>
      )}
    </>
  );
};
