import { Link, LinkProps, useNavigate } from 'react-router-dom';
import RateIcon from '../../assets/rate-icon.svg?react';
import FullBoardIcon from '../../assets/full-board-icon.svg?react';
import RubleIcon from '../../assets/ruble-icon.svg?react';
import ArrowLeftIcon from '../../assets/arrow-left.svg?react';
import { ReactNode, useEffect } from 'react';
import { Button } from '../../components/shared/Button/Button';
import { Details } from '../../components/shared/Details/Details';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../stores/useRootStore';
import { noun } from 'plural-ru';
import { Heading2 } from '../../components/shared/Heading';
import HTMLParser from '../../components/HTMLParser/HTMLParser';

import { WithOrderSummaryLayout } from '../../components/WithOrderSummaryLayout';

// TODO move

export const BackLink = ({ to, children }: { to: LinkProps['to']; children: ReactNode }) => (
  <span className="3xl:absolute 3xl:left-[78px] flex items-center">
    <ArrowLeftIcon />
    <Link to={to} className="px-2 text-lg">
      {children}
    </Link>
  </span>
);

export const Chip = ({ name, icon }: { name: string; icon: ReactNode }) => (
  <span className="flex rounded-full bg-neutral-10 px-3 py-0.5 text-neutral-70">
    {icon}
    <span className="ml-1.5 text-nowrap font-light text-neutral-700">{name}</span>
  </span>
);

export const Rates = observer(() => {
  const { paramsStore } = useRootStore();

  useEffect(() => {
    // Обнулить выбранный тариф при возврате на страницу с тарифами
    paramsStore.selectRate(null);
  }, [paramsStore]);

  return (
    <WithOrderSummaryLayout>

      <BackLink to="/rooms">К выбору номера</BackLink>
      <Heading2 className="mt-8">Выберите тариф</Heading2>
      <div>
        {paramsStore.room?.rates.map((rate) => (
          <Rate
            key={rate.id}
            id={rate.id}
            name={rate.name}
            description={rate.long_description}
            summary={rate.short_description}
            price={rate.total_price}
            nights={rate.nights.length}
            fullBoard={rate.full_boarding}
            cardPayment={rate.card_payment}
          />
        ))}
      </div>
    </WithOrderSummaryLayout>
  );
});

export const Rate = (props: {
  id: number;
  name: string;
  description: string;
  summary: string;
  price: number;
  nights: number;
  fullBoard: boolean;
  cardPayment: boolean;
}) => {
  const { id, name, description, summary, price, nights, fullBoard, cardPayment } = props;

  const { paramsStore } = useRootStore();
  const navigate = useNavigate();

  const selectRate = () => {
    paramsStore.selectRate(id);
    navigate('/services');
  };
  return (
    <article className="mb-8 xl:mb-10">
      <div className="mb-2 flex items-center">
        <RateIcon className=" mr-2 text-hz-0" />
        <h3 className="text-xl leading-[30px]">{name}</h3>
      </div>

      <div className="mb-3 flex overflow-x-auto">
        {fullBoard && <Chip name="Полный пансион" icon={<FullBoardIcon />} />}
        {cardPayment && <Chip name="Оплата банковской картой" icon={<RubleIcon />} />}
      </div>

      <div className="xl:flex xl:flex-row">
        <div className="flex-1">
          <p className="mb-1 line-clamp-4 font-light">
            <HTMLParser html={summary} />
          </p>
          <Details label="Подробнее о тарифе">
            <HTMLParser html={description} />
          </Details>
        </div>

        <div className="xl:ml-6 xl:flex xl:flex-col ">
          <div className="mb-3 mt-4 xl:mb-2 xl:flex xl:flex-col xl:items-end">
            <Price>{Number(price).toLocaleString('ru')}</Price>
            <Nights>{`за ${nights} ${noun(nights, 'ночь', 'ночи', 'ночей')}`}</Nights>
          </div>
          <Button className="w-full px-6 xl:ml-6 xl:w-auto" onClick={() => selectRate()}>
            <span className="xl:hidden">Выбрать тариф</span>
            <span className="hidden xl:block">Выбрать</span>
          </Button>
        </div>
      </div>
    </article>
  );
};

const Price = ({ children }: { children: ReactNode }) => (
  <span className="whitespace-nowrap text-2xl leading-8 text-neutral-900">{children} &#8381;</span>
);

const Nights = ({ children }: { children: ReactNode }) => (
  <span className="ml-2 whitespace-nowrap text-[16px] font-light leading-5 text-neutral-70">
    / {children}
  </span>
);
