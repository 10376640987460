import { useEffect } from 'react';
import { Heading2 } from '../../components/shared/Heading';
import { useRootStore } from '../../stores/useRootStore';
import { BackLink } from '../Rates/Rates';
import { Service } from './Service';
import { observer } from 'mobx-react-lite';
import { WithOrderSummaryLayout } from '../../components/WithOrderSummaryLayout';
import { useRedirectToHomePage } from '../../useRedirectToHomePage';
import { Navigate } from 'react-router-dom';

export const Services = observer(() => {
  const { paramsStore, configStore } = useRootStore();

  useRedirectToHomePage();

  useEffect(() => {
    paramsStore.fetchServices();
  }, [paramsStore]);

  const addService = (params: { serviceId: number; quantity?: number }) => {
    const { serviceId, quantity } = params;

    const service = paramsStore.getService(serviceId);

    service &&
      paramsStore.toggleService({
        serviceId: service.id,
        name: service.name,
        price: service.price,
        quantity: quantity ?? 1,
      });
  };

  const addSubService = (params: {
    serviceId: number;
    subServiceId: number;
    name: string;
    price: number;
  }) => {
    const { serviceId, subServiceId, price, name } = params;
    const service = paramsStore.getService(params.serviceId);

    service && paramsStore.toggleSubService({ serviceId, subServiceId, price, name, quantity: 1 });
  };

  const addQuantityService = (params: {
    serviceId: number;
    quantity: number;
    priceAnnotation: string;
  }) => {
    const { serviceId, quantity, priceAnnotation } = params;
    const service = paramsStore.getService(params.serviceId);

    service &&
      paramsStore.toggleQuantityService({
        serviceId,
        quantity,
        name: service.name,
        price: service.price,
        priceAnnotation,
      });
  };

  return (
    <WithOrderSummaryLayout>
      <div>
        {configStore.shoudSkipServices && <Navigate to="/form" replace />}
        <BackLink to="/rates">К выбору тарифа</BackLink>
        <Heading2 className="mt-8">Дополнительные услуги</Heading2>

        {paramsStore.services.map(service => (
          <Service
            key={service.id}
            service={service}
            addService={serviceId => addService(serviceId)}
            addSubService={params => addSubService(params)}
            addQuantityService={params => addQuantityService(params)}
          />
        ))}
      </div>
    </WithOrderSummaryLayout>
  );
});
